<template>
  <div class="">
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="app-max-container">
      <img :src="image" class="banner" />
      <div class="product_info">
        <div class="product_nav">
          <div class="product_nav_left">
            <!-- <img class="menu" src="@/assets/icon/menu.png"> -->
            <img class="menu" src="@/assets/icon/menus.png" />
            <div class="product_nav_left_title">
              所有分類
              <template v-if="selectOne">> {{ selectOne }}</template>
              <template v-if="selectTwo">> {{ selectTwo }}</template>
            </div>
          </div>
          <div class="product_nav_right">
            <div class="icon">
              <!-- <img class="menu" src="@/assets/icon/kefu.png"> -->
              <a href="/cart"><img class="menu car" src="@/assets/icon/car.png" /></a>
              <!-- <img class="menu xx" src="@/assets/icon/xingxing.png"> -->
            </div>

            <div class="product_query">
              <el-input placeholder="請輸入關鍵字" v-model="keywords">
                <template slot="append">
                  <div class="nav_query_btn" @click="openUrl">
                    <i class="el-icon-search nav_query_btn_search"></i>
                  </div>
                </template>
              </el-input>
            </div>
            <div class="product_desc">
              <el-select v-model="order_by" placeholder="排序设置">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="product_body">
          <div class="product_body_left">
            <el-collapse v-model="activeName">
              <el-collapse-item title="所有分類" name="1">
                <div class="collapse_info" v-for="(item, index) in product_classification" @click="changeOne(item, index)">{{ item.label }}</div>
              </el-collapse-item>
              <el-collapse-item title="全部產品" name="2">
                <div class="collapse_info" v-for="(item, index) in product_name_list" @click="changeTwo(item, index)">{{ item.label }}</div>
              </el-collapse-item>
              <!-- <el-collapse-item title="價格" name="3">
                <div class="product_body_price">
                  <el-slider v-model="price_value" :format-tooltip="formatTooltip"></el-slider>
                  <div class="price_select">
                    <el-input v-model="price" placeholder="请输入内容"></el-input>
                    <span class="line"></span>
                    <el-input v-model="max_price" placeholder="请输入内容"></el-input>
                  </div>

                </div>
              </el-collapse-item> -->
              <!-- <el-collapse-item title="推薦產品" name="4" class="last">
                <div class="xgcp_product">
                  <a class="tjproduct" v-for="item in tuijian_product.lst" :href="item.link" >
                    <img class="tjproduct_img" :src="item.image">
                    <div class="tx_product_info">
                      <p class="title">{{ item.title }}</p>
                      <p class="price">$ {{ item.price }}</p>
                    </div>
                  </a>
                </div>
                <img class="tuijian_product_image" :src="tuijian_product.image">
              </el-collapse-item> -->
            </el-collapse>
          </div>
          <div class="product_body_right">
            <div v-if="product_list.list.length == 0">暫無相關產品</div>
            <div class="product_list">
              <a :href="`/details/${item.id}`" class="prduct" v-for="item in product_list.list">
                <img class="prduct_img" :src="item.product_image" />
                <p class="title">{{ item.product_title }}</p>
                <!-- <p class="price" v-if="item.product_price == item.max_product_price">$ {{ item.product_price }}</p>
                <p class="price" v-else>$ {{ item.product_price }} ~ {{ item.max_product_price }}</p> -->
                <p class="price" v-if="item.product_price.length == 1 && item.product_price[0]>0"> $ {{ item.product_price[0] }}</p>
                <p class="price" v-if="item.product_price.length ==2  ">
                  <span v-if="item.product_price.length ==2 && item.product_price[0]>0">
                    ${{ item.product_price[0] }}
                  </span>
                   <span v-if="item.product_price.length ==2 &&item.product_price[1]>0">
                    ~{{ item.product_price[1] }}
                   </span>
                </p>
                <span class="btn">加入購物車</span>
              </a>
            </div>
            <div class="pages">
              <div class="query_list">
                <span>
                  <a @click="setProduct('')">
                    <i class="el-icon-search"></i>
                    全部
                  </a>
                </span>
                <span v-for="item in query_list">
                  <a @click="setProduct(item)">
                    <i class="el-icon-search"></i>
                    {{ item }}
                  </a>
                </span>
              </div>
              <div class="page" v-if="product_list.count">
                <el-pagination
                  @current-change="handleCurrentChange"
                  small
                  :page-size="16"
                  layout="prev, pager, next"
                  :total="product_list.count"></el-pagination>
              </div>
            </div>
            <div class="images_list">
              <img :src="item" v-for="item in images_list" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <appLink></appLink>

    <appFooter></appFooter>
  </div>
</template>

<script>
import { getProductFenLei, getProductListApi } from "@/api/index.js";
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import appLink from "@/components/link.vue";

export default {
  components: {
    appHeader,
    appFooter,
    appNav,
    appLink
  },
  watch: {
    order_by() {
      this.getProductList();
    }
  },
  data() {
    return {
      product_classification: [],
      // tuijian_product: {
      //   lst: [],
      //   image: ""
      // },
      query_list: [],
      images_list: [],
      image: "",
      keywords: "",
      order_by: "",
      page: 1,
      activeName: ["1", "2", "3", "4"],
      options: [
        {
          value: "",
          label: "預設排序"
        },
        {
          value: "desc",
          label: "價格順序"
        },
        {
          value: "asc",
          label: "價格倒序"
        }
      ],
      product_name_list: [],
      price_value: 0,
      price: 0,
      max_price: 10000,
      product_list: {
        list: [],
        count: 0
      },
      page: 1,
      selectOne: "",
      selectTwo: "",
      selectItem: {
        id: 0
      },
      selectTwoItem: {
        id: 0
      }
    };
  },
  created() {
    var url = window.location.href;
    var params = new URLSearchParams(url.split("?")[1]);
    if (params.get("query")) {
      this.keywords = params.get("query");
    }
    getProductFenLei().then((res) => {
      this.product_classification = res.data.list;
      this.product_name_list = this.product_classification[0].children;
      this.query_list = res.data.query_list;
    });
    this.getProductList();
  },
  methods: {
    handleCurrentChange(e) {
      this.page = e;
      this.getProductList();
    },
    openUrl() {
      window.location.href = "/product?query=" + this.keywords;
    },
    formatTooltip(val) {
      this.price = val * 10;
      return val * 10;
    },
    setProduct(item) {
      this.keywords = item;
      if (item == "") {
        this.selectTwo = "";
        this.selectTwoItem = {
          id: 0
        };
        this.selectTwo = "";
      }
      this.getProductList();
    },
    // jump(id) {
    //   // if()
    //   // this.$router.push({ path: "/details/" + id });
    //   var token = localStorage.getItem("token");
    //   if (token) {
    //     // this.product_id = this.$route.params.id;
    //     // this.getProductInfo();
    //   } else {
    //     this.$router.push({ path: "/login" });
    //   }
    // },
    changeOne(item, index) {
      this.keywords = "";
      if (this.selectOne == item.label) {
        this.selectTwo = "";
        this.selectTwoItem = {
          id: 0
        };
      } else {
        this.selectTwoItem.id = 0;
        this.product_name_list = this.product_classification[index].children;
        this.selectOne = item.label;
        this.selectTwo = "";
        this.selectItem = item;
      }
      this.getProductList();
    },
    changeTwo(item, index) {
      this.selectItem.id = item.pid;
      this.product_classification.forEach((element) => {
        if (element.id == item.pid) {
          this.selectOne = element.label;
        }
      });
      this.keywords = "";
      if (this.selectTwo == item.label) {
      } else {
        this.selectTwo = item.label;
        this.selectTwoItem = item;
      }
      this.getProductList();
    },
    getProductList() {
      getProductListApi({
        keywords: this.keywords,
        flo_id: this.selectItem.id,
        flt_id: this.selectTwoItem.id,
        order_by: this.order_by,
        // price: this.product_price,
        // max_price: this.max_price,
        page: this.page
      }).then((res) => {
        this.product_list = res.data;
      });
    }
  }
};
</script>
<style lang="scss">
.nav_query_btn_search {
  width: 30px;
  text-align: center;
}
.el-pagination--small .el-pager li {
  color: #002557 !important;
  line-height: 20px !important;
}
.el-pager li.active {
  color: #002557 !important;
  border: 1px solid #002557 !important;
  border-radius: 50%;
}
.price_select {
  .el-input__inner {
    border: none !important;
    text-align: center;
    font-size: 16px !important;
    height: 30px !important;
    line-height: 30px !important;
    font-weight: bold;
  }
}

.el-slider__bar {
  background: #5b5b5b !important;
  height: 3px !important;
}

.el-slider__runway {
  height: 3px !important;
}

.el-slider__button {
  border: 1px solid #5b5b5b !important;
}

.el-slider__button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: #5b5b5b;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.el-collapse-item__content {
  padding-bottom: 15px !important;
}

.el-collapse-item__content:nth-child(1) {
  margin-top: 15px;
}

.el-collapse-item__header {
  background: #697687 !important;
  padding-left: 20px !important;
  color: #fff !important;
  font-size: 14px !important;
  height: 40px !important;
  line-height: 40px !important;
}
.el-collapse-item__wrap {
  background: #f6f6f6 !important;
}
.el-select-dropdown__item.selected {
  color: #5b5b5b !important;
}

.product_desc {
  .el-select {
    width: 140px;

    .el-input--suffix {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-input__icon {
        line-height: 40px !important;
      }

      .el-input__inner {
        background: #697687 !important;
        color: #fff !important;
        padding-right: 0 !important;
        padding-left: 25px !important;
        font-size: 18px !important;
        height: 40px !important;
      }
    }
  }
}

.product_query {
  width: 300px !important;
  margin-left: 15px;

  .el-input {
    .el-input__inner {
      height: 40px !important;
    }

    .el-input__inner,
    .el-input__inner::placeholder {
      background: #f6f6f6 !important;
      color: #5b5b5b !important;
      font-size: 18px !important;
    }

    .el-input-group__append {
      cursor: pointer;
      padding: 0 4px !important;
      background: #fff;
      border: 2px solid #697687 !important;
      .menu {
        width: 15px;
      }
    }
  }
}
.last {
  .el-collapse-item__content {
    padding-bottom: 0 !important;
    line-height: 0 !important;
  }
}
</style>
<style lang="scss" scoped>
.images_list {
  display: flex;
  justify-content: space-between;
  img {
    width: 130px;
    height: 130px;
  }
}
.pages {
  padding: 30px;
  display: flex;
  .page {
    width: 250px;
  }
  .query_list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: left;
    span {
      margin-bottom: 10px;
      border: 1px solid #d6dbde;
      padding: 2px 10px;
      border-radius: 20px;
      cursor: pointer;
      margin-right: 10px;
      font-size: 12px;
      .el-icon-search {
        padding-right: 5px;
      }
    }
  }
}
.product_body_right {
  margin-left: 20px;
  .product_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    .prduct {
      width: 210px;
      text-align: center;
      margin-bottom: 40px;
      .prduct_img {
        width: 100%;
      }
      .title {
        padding: 0 20px;
        margin-top: 10px;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* 定义显示的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 20px;
      }
      .price {
        margin-top: 15px;
        text-align: center;
        margin-bottom: 15px;
      }
      .btn {
        text-align: center;
        background: #002557;
        color: #ffffff;
        display: inline-block;
        font-size: 12px;
        padding: 5px 15px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
.xgcp_product {
  padding: 0 40px;

  .tjproduct {
    border: 1px solid #d6dbde;
    border-top: 0;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    background-color: #fff;
    .tjproduct_img {
      width: 70px;
      height: 70px;
    }
    .tx_product_info {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      p {
        flex: 1;
        line-height: 1.769230769230769;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
      }
      .price {
        display: flex;
        font-size: 18px;
        align-items: flex-start;
      }
    }
  }
  .tjproduct:nth-child(1) {
    border-top: 1px solid #d6dbde;
  }
}
.tuijian_product_image {
  margin-top: 20px;
}
.product_body_price {
  padding: 0 20px;
}

.price_select {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #5b5b5b;

  .line {
    width: 40px;
    display: inline-block;
    height: 4px;
    background-color: #5b5b5b;
    margin: 0 20px;
  }
}

.collapse_info {
  padding-left: 40px;
  font-size: 16px;
  cursor: pointer;
}

.collapse_info:hover {
  background: #697687;
  color: #fff;
  transition: all 0.3s ease;
}
.collapse_info_active {
  background: #697687;
  color: #fff;
}

.product_body {
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  .product_body_left {
    width: 800px;
    min-height: 500px;
    padding: 0 20px;
    // width: -webkit-fill-available;
  }
}

.product_desc {
  margin-left: 20px;
  margin-right: 20px;
}

.banner {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.product_info {
  border-top: 10px solid #5b5b5b;
}

.product_nav {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #000000;

  .product_nav_left {
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .menu {
      width: 38px;
      margin-right: 5px;
    }

    .product_nav_left_title {
      color: #5b5b5b;
      margin-left: 10px;
      font-size: 20px;
    }
  }

  .product_nav_right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon {
      .menu {
        width: 25px;
        margin-right: 10px;
      }

      .menu.car {
        width: 30px;
        margin-left: 10px;
      }
    }
  }
}
.collapse_info {
  overflow-wrap: anywhere;
  padding-right: 20px;
}
</style>
