<template>
  <div class="">
    <el-dialog title="编辑地址" :visible.sync="dialogVisible" width="600px">
      <div>
        <el-form ref="form" :model="address" label-width="80px" class="address">
          <div class="address_one">
            <el-form-item label="收貨人">
              <el-input v-model="address.contact_person" style="width: 270px"></el-input>
            </el-form-item>
            <el-form-item label="收貨電話">
              <el-input v-model="address.contact_phone" style="width: 270px"></el-input>
            </el-form-item>
            <el-form-item label="聯係郵箱">
              <el-input v-model="address.contact_email" style="width: 270px"></el-input>
            </el-form-item>
          </div>
          <div class="address_two">
            <el-form-item label="收穫地址">
              <el-cascader
                ref="cascaderRef"
                @change="selectAddress"
                :props="{ value: 'area_code', label: 'title' }"
                clearable
                filterable
                :options="addressInfo"
                v-model="addressModelValue"
                style="width: 450px"></el-cascader>
            </el-form-item>
            <el-form-item label="詳細地址">
              <el-input type="input  " v-model="address.address_info" style="width: 270px"></el-input>
            </el-form-item>
            <el-form-item label="" style="width: 270px"></el-form-item>
          </div>
          <!-- <div class="address_two">
          <el-form-item label="詳細地址">
            <el-input  type="input  " v-model="address.address_info"
              style="width: 270px;"></el-input>
          </el-form-item>
          <el-form-item label="備注">
            <el-input type="input" v-model="address.remake" style="width: 270px;"></el-input>
          </el-form-item>
          <el-form-item label="" style="width: 270px;">
          </el-form-item>
        </div> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AreaJson from "@/json/area.json";

import { userAddressDetailsApi, userAddressSaveApi } from "@/api/index.js";
export default {
  data() {
    return {
      dialogVisible: false,
      address: {
        id: "",
        address_info: "",
        contact_email: "",
        contact_phone: "",
        contact_person: "",
        remake: "",
        province_name: "",
        province_id: "",
        city_name: "",
        city_id: "",
        area_name: "",
        area_id: ""
      },
      addressInfo: AreaJson,
      addressModelValue: []
    };
  },
  methods: {
    open(addressId) {
      if (addressId) {
        this.address.id = addressId;
        this.userAddressDetails();
      }
      this.dialogVisible = true;
    },
    close() {},
    userAddressDetails() {
      userAddressDetailsApi({ id: this.address.id }).then((res) => {
        this.address = res.data;
        this.addressModelValue = [res.data.province_id, res.data.city_id, res.data.area_id];
      });
    },
    selectAddress(address_info) {
      this.address.province_id = address_info[0];
      this.address.city_id = address_info[1];
      this.address.area_id = address_info[2];
      var city_label = [];
      AreaJson.forEach((item, index) => {
        if (item.area_code == address_info[0]) {
          city_label[0] = item.title;
          item.children.forEach((city, ind) => {
            if (city.area_code == address_info[1]) {
              city_label[1] = city.title;
              city.children.forEach((area, ins) => {
                if (area.area_code == address_info[2]) {
                  city_label[2] = area.title;
                }
              });
            }
          });
        }
      });
      this.address.province_name = city_label[0];
      this.address.city_name = city_label[1];
      this.address.area_name = city_label[2];
    },
    submit() {
      var that = this;
      var address = that.address;
      if (address.contact_person == "") {
        that.$message.error("收貨人不能爲空");
        return;
      }
      if (address.contact_phone == "") {
        that.$message.error("收貨電話不能爲空");
        return;
      }
      if (address.contact_email == "") {
        that.$message.error("聯係郵箱不能爲空");
        return;
      }
      if (address.province_name == "" || address.city_name == "" || address.area_name == "") {
        that.$message.error("请完善收貨地址信息");
        return;
      }
      if (address.address_info == "") {
        that.$message.error("詳細地址不能爲空");
        return;
      }

      //郵箱校驗
      //手机号码校驗
      var email_reg = validateEmailNumber(address.contact_email);
      var mobile_reg = validatePhoneNumber(address.contact_phone);

      if (!mobile_reg) {
        that.$message.error("请輸入正確手機號碼");
        return;
      }
      if (!email_reg) {
        that.$message.error("请輸入正確郵箱");
        return;
      }
      userAddressSaveApi(this.address).then((res) => {
        this.dialogVisible = false;
        this.$emit("submit");
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
