<template>
  <div>
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="login_box">
      <div class="title">新注冊賬戶</div>
      <div class="from_item">
        <div class="from_item_title">
          用戶名
          <span>*</span>
        </div>
        <el-input placeholder="" v-model="user.nickname"></el-input>
      </div>
      <div class="from_item">
        <div class="from_item_title">
          聯係電話
          <span>*</span>
        </div>
        <el-input placeholder="例如 +852622139561" v-model="user.mobile" ></el-input>
      </div>
      <div class="from_item">
        <div class="from_item_title">
          公司名稱
        </div>
        <el-input v-model="user.company_name" ></el-input>
      </div>
      <div class="from_item">
        <div class="from_item_title">
          電子郵箱
          <span>*</span>
        </div>
        <el-input placeholder="" v-model="user.email"></el-input>
      </div>
      <div class="from_item code_text">
        <div class="from_item_title">
          郵箱驗證碼
          <span>*</span>
        </div>
        <el-input placeholder="" v-model="user.email_code">
          <el-button slot="suffix"  class="get_code" @click="code" :disabled="disres">
            {{ text }}
          </el-button>
        </el-input>
      </div>      
      <div class="from_item">
        <div class="from_item_title">
          密碼
          <span>*</span>
        </div>
        <el-input :type="passwordState ? 'password' : 'text'" placeholder="" v-model="user.password">
          <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword()"></i>
        </el-input>
      </div>
      <div class="from_item">
        <div class="from_item_title">
          確認密碼
          <span>*</span>
        </div>
        <el-input :type="passwordState ? 'password' : 'text'" placeholder="" v-model="user.two_password">
          <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword()"></i>
        </el-input>
      </div>
      <div class="from_item">
        <el-button class="login_btn" @click="registerFrom" :disabled="disres">注冊</el-button>
      </div>
      <div class="from_footer">
        <a class="aflex" href="/login">登錄</a>
        <a class="aflex" href="/forgot">忘記密碼?</a>
      </div>
    </div>
    <appFooter></appFooter>
  </div>
</template>
<script>
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import { registerApi,getCodeApi } from "@/api/index.js";
import { validatePhoneNumber } from "@/utils/phone.js";
import { validateEmailNumber } from "@/utils/email.js";

export default {
  components: {
    appHeader,
    appFooter,
    appNav
  },
  data() {
    return {
      user: {
        nickname: "",
        mobile: "",
        company_name: "",
        email: "",
        email_code:"",
        password: "",
        two_password: ""
      },
      text: "獲取驗證碼",
      code_state: true,
      passwordState: true,
      disres: false
    };
  },
  methods: {
    showPassword() {
      this.passwordState = !this.passwordState;
    },
    registerFrom() {
      var that = this;
      if (that.user.nickname == "") {
        that.$message.error("名字不能爲空");
        return;
      }
      if (this.user.mobile == "") {
        this.$message.error("聯係電話不能爲空");
        return;
      }
      if (this.user.email == "") {
        this.$message.error("郵箱不能爲空");
        return;
      }
      if (this.user.email_code == "") {
        this.$message.error("驗證碼不能爲空");
        return;
      }
     
      //郵箱校驗
      //手机号码校驗
      var email_reg = validateEmailNumber(this.user.email);
      console.log(this.user.mobile)
      var mobile_reg = validatePhoneNumber(this.user.mobile);

      if (!mobile_reg) {
        that.$message.error("请輸入正確手機號碼");
        return;
      }
      console.log(email_reg)
      if (!email_reg) {
        that.$message.error("请輸入正確郵箱");
        return;
      }
      if (this.user.password == "") {
        this.$message.error("密碼不能爲空");
        return;
      }
      if (this.user.password != this.user.two_password) {
        this.$message.error("兩次密碼輸入不一致");
        return;
      }
     
      this.disres = true;
      registerApi(this.user).then((res) => {
        this.disres = false;
        if (res.code == 200) {
          this.$message.success("注冊成功，自動登錄成功");
          localStorage.setItem("token", res.data.token_unique);
          localStorage.setItem("user", JSON.stringify(res.data));
          this.$router.push({ path: "/" });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    code() {
      if (this.code_state) {
        if (this.user.email == "") {
          this.$message.error("郵箱不能爲空");
          return;
        }
        this.disres = true;
        getCodeApi({
          email: this.user.email
        }).then((res) => {
          this.disres = false;
          if (res.code == 200) {
            this.$message.success(res.msg);
            var num = 60;
            var t = setInterval(() => {
              this.text = num + "s";
              num--;
              if (num == 0) {
                this.code_state = true;
                this.text = "重新獲取";
                clearInterval(t);
              }
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.get_code {
  //width: 100px;
  border-right: none;
  background: #2e4f76;
  color: #fff;
//   right: 0 !important;
    margin-right: -5px;
}

.get_code:hover,
.get_code:active,
.get_code:focus {
  background: #2e4f76;
  color: #fff;
}
.from_footer {
  margin-top: 20px;
  display: flex;
  justify-content: end;

  .aflex {
    margin-left: 40px;
    color: #2e4f76;
  }
}

.login_btn {
  width: 100%;
  height: 45px;
  background: #2e4f76;
  border-radius: 17px;
  color: #fff;
}

.login_btn:hover,
.login_btn:active,
.login_btn:focus {
  background: #2e4f76;
  color: #fff;
}

.from_item {
  margin-bottom: 30px;
}

.from_item_title {
  margin-bottom: 20px;
  span {
    color: red;
  }
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  color: #2e4f76;
  line-height: 150px;
}

.login_box {
  padding: 50px 200px;
  width: 1176px;
  background: #ffffff;
  box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  border: 1px solid #464646;
  margin: 100px auto;
}
</style>
