<template>
  <div>
    <appHeader></appHeader>
    <appNav></appNav>
    <div class="goods-details app-max-container">
      <div class="goods_left">
        <el-carousel :interval="5000" arrow="always" class="carousel">
          <el-carousel-item v-for="(item, index) in goodsImage" :key="index" class="carousel">
            <img class="current-img" :src="item" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="goods_right">
        <p class="goods_title">{{ productInfo.product_title }}</p>
        <!-- <div class="price_box">
          <p class="price">
            價格：$
            <span class="price_text price_text_price" v-if="productInfo.product_price.length == 1 && productInfo.product_price[0] > 0">
              {{ productInfo.product_price[0] }}
            </span>
            <span class="price_text price_text_price" v-if="productInfo.product_price.length > 1">
              <span v-if="productInfo.product_price[0] > 0">
                {{ productInfo.product_price[0] }}
              </span>
              <span v-if="productInfo.product_price[1] > 0">
                ~{{ productInfo.product_price[1] }}
              </span>
            </span>
          </p>
        </div> -->
        <!-- <div class="price_box">
          <p class="price">
            價格：$
            <span class="price_text price_text_price" v-if="productInfo.product_price == productInfo.max_product_price">{{ productInfo.product_price }}</span>
            <span class="price_text price_text_price" v-else>{{ productInfo.product_price }} ~ {{ productInfo.max_product_price }}</span>

          </p>
        </div> -->

        <div class="spec-wrap m-t-20">
          <div class="spec flex m-b-16" v-for="(item, index) in goodsSpec" :key="index">
            <div class="lighter spec-name">{{ item.name }}</div>
            <div class="spec-list flex flex-wrap">
              <div
                :class="['spec-item lighter', { active: specitem.checked }]"
                v-for="(specitem, sindex) in item.spec_list"
                :key="sindex"
                @click="onChoseSpecItem(item.name, specitem.value)">
                {{ specitem.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="goods-num flex">
          <div class="num lighter">數量</div>
          <NumberBox v-model="goodsNum" :min="1" :max="checkedGoods.sku_stock" />
          <div class="kc">
            庫存: {{ checkedGoods.sku_stock }}
            <span style="padding-left: 20px" v-if="productInfo.unit && productInfo.unit.unit_name">單位:{{ productInfo.unit.unit_name }}</span>
          </div>
        </div>
        <div class="goods-btns flex">
          <div class="btn primary addcart" style="cursor: pointer" @click="onAddCart">加入購物車</div>
          <div @click="jumpCart" style="margin-left: 20px; display: flex; cursor: pointer"><img class="menu car" src="@/assets/icon/car.png" /></div>
        </div>
      </div>
    </div>
    <div class="goods-details app-max-container">
      <div class="details bg-white flex-1">
        <el-tabs v-model="active">
          <el-tab-pane label="產品詳情">
            <div class="rich-text">
              <img v-for="(item, index) in productInfo.pc_content" :src="item" :key="index" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="產品參數" v-if="productInfo.product_specs.length > 0">
            <div class="parameter">
              <div class="parameter_item" v-for="(item, index) in productInfo.product_specs">
                <span class="parameter_item_label">{{ item.label }}</span>
                <span>{{ item.value }}</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <appFooter></appFooter>
  </div>
</template>
<script>
import appHeader from "@/components/header.vue";
import appFooter from "@/components/footer.vue";
import appNav from "@/components/nav.vue";
import NumberBox from "@/components/numberBox";
import { getProductInfoApi, buyProductApi, addCartProductApi } from "@/api/index.js";

export default {
  components: {
    appHeader,
    appFooter,
    appNav,
    NumberBox
  },
  data() {
    return {
      product_id: 0,
      active: "0",
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        preventClicks: true,
        slidesPerView: "auto"
      },
      swiperIndex: 0,
      goodsNum: 1,
      productInfo: {
        product_title: "",
        content: "",
        unit: [],
        product_specs: [],
        sku_list: []
      },
      spec_value: [],
      goodsSpec: [],
      checkedGoods: {},
      goodsImage: []
    };
  },
  created() {
    this.product_id = this.$route.params.id;
    this.getProductInfo();
    // var token = localStorage.getItem("token");
    // if(token){
    //     this.product_id = this.$route.params.id;
    //     this.getProductInfo();
    // }else{
    //
    //     this.$router.push({path:'/login'});
    // }
  },
  watch: {
    goodsSpec: {
      immediate: true,
      handler(value) {
        const goods_item = this.productInfo.sku_list;

        let keyArr = [];
        value.forEach((item) => {
          if (item.spec_list) {
            item.spec_list.forEach((specitem) => {
              if (specitem.checked) {
                keyArr.push(specitem.value);
              }
            });
          }
        });
        if (!keyArr.length) {
          return;
        }

        let key = keyArr.join(",");

        let index = goods_item.findIndex((item) => {
          return item.sku_name == key;
        });

        if (index == -1) {
          index = 0;
        }

        this.checkedGoods = goods_item[index];
      }
    }
  },
  methods: {
    onBuyNow() {
      if (this.goodsNum > this.checkedGoods.stock) {
        this.$message.error("庫存不足");
        return;
      }
      buyProductApi({
        goods_id: this.checkedGoods.goods_id,
        sku_id: this.checkedGoods.id,
        goodsNum: this.goodsNum
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    jumpCart() {
      var token = localStorage.getItem("token");
      if (!token) {
        this.$router.push({ path: "/login" });
        return;
      } else {
        this.$router.push({ path: "/cart" });
      }
    },
    onAddCart() {
      var token = localStorage.getItem("token");
      if (!token) {
        this.$router.push({ path: "/login" });
        return;
      }

      if (this.goodsNum > this.checkedGoods.stock) {
        this.$message.error("庫存不足");
        return;
      }

      addCartProductApi({
        product_info_id: this.checkedGoods.product_info_id,
        sku_unique: this.checkedGoods.sku_unique,
        operate_number: this.goodsNum
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getProductInfo() {
      getProductInfoApi({ product_id: this.product_id }).then((res) => {
        var product_info = res.data;
        this.goodsImage = product_info.slider_image;
        this.spec_value = product_info.spec_value;
        this.productInfo = product_info;
        this.spec_value.forEach((item) => {
          item.spec_list.forEach((specitem, specindex) => {
            if (specindex == 0) {
              specitem.checked = 1;
            } else {
              specitem.checked = 0;
            }
          });
        });
        if (product_info.sku_list.length == 1) {
          //當前只有一個商品時，默認選中
          var sku = product_info.sku_list[0];
          this.checkedGoods.product_info_id = sku.product_info_id;
          this.checkedGoods.sku_unique = sku.sku_unique;
        }
        this.goodsSpec = this.spec_value;
      });
    },
    onChoseSpecItem(name, value) {
      const { goodsSpec } = this;
      goodsSpec.forEach((item) => {
        if (item.spec_list && item.name == name) {
          item.spec_list.forEach((specitem) => {
            specitem.checked = 0;
            if (specitem.value == value) {
              specitem.checked = 1;
            }
          });
        }
      });

      this.goodsSpec = [...goodsSpec];
    }
  }
};
</script>
<style>
input {
  font-family: PingFang SC, Arial, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  font-size: 14px;
  outline: none;
  border: none;
}
.rich-text {
  display: flex;
  flex-direction: column;
}
.rich-text img {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.details {
  min-height: 500px;
  width: 100%;
}
.parameter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .parameter_item {
    width: 33.33%;
    margin-bottom: 30px;
    font-size: 12px;
    .parameter_item_label {
      display: inline-block;
      width: 150px;
      color: #999;
    }
  }
}
.rich-text {
  display: flex;
  justify-content: center;
  width: 100%;
}

.kc {
  margin-left: 20px;
}

.goods-num {
  margin-bottom: 30px;
  margin-top: 20px;

  .num {
    margin-right: 20px;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.old_price {
  text-decoration: line-through;
  padding-left: 20px;
}

.price {
  margin-top: 10px;
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;

  .price_text {
    color: #002557;
  }

  .price_text_price {
    font-size: 24px;
  }
}

.price_box {
  margin-top: 20px;
  background: #f6f6f6;
  background-size: 100%;
  padding: 16px 16px;
}

.goods_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 定义显示的行数 */
  overflow: hidden;
  text-overflow: ellipsis;
}

.carousel {
  width: 430px;
  height: 430px;
}

.goods-details {
  display: flex;
  margin-top: 20px;
}

.goods_right {
  flex: 1;
  margin-left: 50px;
}

.goods_left {
  width: 430px;
  border-radius: 4px;
}

.current-img {
  width: 430px;
  height: 430px;
}

.spec-wrap {
  margin-top: 20px;

  .spec {
    align-items: flex-start;

    .spec-name {
      margin-right: 20px;
      margin-top: 6px;
      flex: none;
    }

    .spec-item {
      padding: 0 20px;
      line-height: 32px;
      border: 1px solid #002557;
      border-radius: 2px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      &.active {
        color: #fff;
        background-color: #002557;
        border-color: currentColor;
      }
    }
  }
}

.goods-btns {
  .btn {
    line-height: 42px;
    border-radius: 2px;
    margin-right: 14px;
    text-align: center;
    width: 140px;
    border: 1px solid #002557;
    cursor: pointer;
    background: #002557;
    color: #fff;

    &.addcart {
      color: #002557;
      background-color: #fff;
      border-color: #002557;
    }

    &.collection {
      width: 146px;
      color: #666;
      background-color: transparent;
      border-color: #002557;

      .start-icon {
        width: 18.5px;
        height: 18px;
      }
    }
  }
}
</style>
