import request from "@/store/modules/request";
export function getConfig() {
  return request({
    method: 'post',
    url: "config/index"
  });
}
export function getIndex() {
  return request({
    method: 'post',
    url: "index/index"
  });
}

export function getAbout() {
  return request({
    method: 'post',
    url: "index/about"
  });
}
export function getSelf() {
  return request({
    method: 'post',
    url: "index/self"
  });
}
export function getSend() {
  return request({
    method: 'post',
    url: "index/send"
  });
}

export function getPz() {
  return request({
    method: 'post',
    url: "index/pz"
  });
}



export function getProductConfig() {
  return request({
    method: 'post',
    url: "index/product_config"
  });
}
export function getProduct() {
  return request({
    method: 'post',
    url: "index/product_lst"
  });
}

export function registerApi(data) {
  return request({
    method: "post",
    url: "/register",
    data: data
  });
}


export function LoginApi(data) {
  return request({
    method: "post",
    url: "/login",
    data: data
  });
}
export function getCodeApi(data) {
  return request({
    method: "post",
    url: "/email_code",
    data: data
  });
}

export function respasswordApi(data) {
  return request({
    method: "post",
    url: "/res_password",
    data: data
  });
}
export function resemailApi(data) {
  return request({
    method: "post",
    url: "/res_email",
    data: data
  });
}

export function getProductFenLei() {
  return request({
    method: "post",
    url: "product/category"
  });
}
export function getProductListApi(data) {
  return request({
    method: "post",
    url: "product/list",
    data: data
  });
}

export function getProductInfoApi(data) {
  return request({
    method: "post",
    url: "product/detail",
    data: data
  });
}

export function buyProductApi(data) {
  return request({
    method: 'post',
    url: "product/buy",
    data:data
  });
}
export function addIndexUserLinkedtApi(data) {
  return request({
    method: "post",
    url: "/inquiry",
    data: data
  });
}

export function addCartProductApi(data) {
  return request({
    method: 'post',
    url: "product/cart",
    data:data
  });
}
export function userCartProductApi(data) {
  return request({
    method: "post",
    url: "product/cart_list",
    data: data
  });
}
export function userCartCreateOrderApi(data) {
  return request({
    method: "post",
    url: "product/saveorder",
    data: data
  });
}
export function userAddressApi(data) {
  return request({
    method: "post",
    url: "product/user_address",
    data: data
  });
}
export function userAddressDetailsApi(data) {
  return request({
    method: "post",
    url: "product/user_address_details",
    data: data
  }); 
}
export function userAddressSaveApi(data) {
  return request({
    method: "post",
    url: "product/user_address_save",
    data: data
  });
}
export function userAddressDelApi(data) {
  return request({
    method: "post",
    url: "product/user_address_del",
    data: data
  });
}
export function userAddressDefaultApi(data) {
  return request({
    method: "post",
    url: "product/user_address_default",
    data: data
  });
}
export function userAddressListApi(data) {
  return request({
    method: "post",
    url: "product/user_address_list",
    data: data
  });
}
export function onChangeUserCartApi(data) {
  return request({
    method: "post",
    url: "product/change_user_cart",
    data: data
  });
}
export function delUserCartProductApi(data) {
  return request({
    method: "post",
    url: "product/del_user_cart_product",
    data: data
  });
}

export function getUserInfoApi() {
  return request({
    method: "post",
    url: "/userinfo"
  });
}
export function editUserInfoApi(data) {
  return request({
    method: "post",
    url: "/edit_user_info",
    data:data
  });
}

export function orderListApi(data) {
  return request({
    method: "post",
    url: "/order_list",
    data:data
  });
}

export function addressListApi(data) {
  return request({
    method: "post",
    url: "/address_list",
    data:data
  });
}
export function saveUserAddressApi(data) {
  return request({
    method: "post",
    url: "/save_address",
    data:data
  });
}
